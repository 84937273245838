import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        name: '子域名查询',
        component: () => import('../components/Index.vue')
    },
    {
        path: '/login',
        title: '登录',
        component: () => import('../components/Login/Login.vue')  //组件页面地址
    }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

export default router